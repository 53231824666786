import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Angular modules
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog'; // Importa MatDialogModule
import { MatButtonModule } from '@angular/material/button';

// Custom services 
import { LanguageService } from '../../../services/language/language.service';

/* Specify the structure of the dictionary that will receive the dialog when being openned */
export interface DialogData {informationTitle:string, informationMessage:string}



@Component({
  selector: 'app-confirmation-popup',
  standalone: true,
  imports: [
    // Angular modules
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './confirmation-popup.component.html',
  styleUrl: './confirmation-popup.component.css'
})
export class ConfirmationPopupComponent {

  public informationTitle:string='';
  public informationMessage:string='';

  constructor(public languageService:LanguageService,
              public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) 
  {
    this.informationTitle = data.informationTitle
    this.informationMessage = data.informationMessage
  }

  // ----- Button actions ----- //

  cancelButtonClicked(){
    this.dialogRef.close(false)
  }
  acceptButtonClicked(){
    this.dialogRef.close(true)
  }


}
