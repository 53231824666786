import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

// Import controller service
import { ControllerService } from './services/controller/controller.service';

// Import environment variables
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  
  title = environment.appName;
  
  constructor(
    public controllerService:ControllerService,
  ){}

  ngOnInit() {
    this.controllerService.initializeApp();
  }
}
