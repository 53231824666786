import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth, GoogleAuthProvider } from '@angular/fire/auth';

import { ControllerService } from './services/controller/controller.service';



export const appConfig: ApplicationConfig = {
  providers: [
    // Router
    provideRouter(routes),
    // Animations
    provideAnimationsAsync(),
    // Http client
    importProvidersFrom(HttpClientModule),
    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    GoogleAuthProvider,
    // Controller service
    ControllerService,
  ]
};




/*
import { ScreenTrackingService, UserTrackingService, getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
*/


/*
provideAnalytics(() => getAnalytics()),
provideFirestore(() => getFirestore()),
provideFunctions(() => getFunctions()),
provideMessaging(() => getMessaging()),
providePerformance(() => getPerformance()),
provideStorage(() => getStorage()),
*/
