import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { StringsEN } from '../../constants/language-strings/strings-en';
import { StringsES } from '../../constants/language-strings/strings-es';



@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public availableLanguages = [
    {languageCode: 'en', languageString: 'EN', languageFlag: 'assets/countries/EN_Flag.png'},
    {languageCode: 'es', languageString: 'ES', languageFlag: 'assets/countries/ES_Flag.png'},
  ]

  public Strings:any = {}
  //public languageCode:string = 'en'
  public languageString:string = 'EN'
  public languageFlag:string = 'assets/countries/EN_Flag.png'

  public languageCode: BehaviorSubject<string> = new BehaviorSubject<string>('en');

  constructor() { }
  
  setLanguage(language:string){
    if(language == 'es'){
      // Spanish
      this.Strings =  StringsES
      //this.languageCode = 'es'
      this.languageCode.next('es')
      this.languageString = 'ES'
      this.languageFlag = 'assets/countries/ES_Flag.png'
    }else{
      // English (default)
      this.Strings = StringsEN
      //this.languageCode = 'en'
      this.languageCode.next('en')
      this.languageString = 'EN'
      this.languageFlag = 'assets/countries/EN_Flag.png'
    }
  }


  setLanguageAccordingToNavigatorLanguage(){
    let navigatorLanguage = navigator.language
    if(navigatorLanguage.includes('es')){
      this.setLanguage('es')
    }else{
      this.setLanguage('en')
    }
  }



  getCurrentLanguageCode(): Observable<string> {
    return this.languageCode.asObservable();
  }

  
}
