import { inject, Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import { Auth, GoogleAuthProvider, authState, signOut, signInWithPopup } from '@angular/fire/auth';


// Dialog popups
import { MatDialog } from '@angular/material/dialog';


// Custom services
import { UtilsService } from '../utils/utils.service';
import { LanguageService } from '../language/language.service';



@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {


  private auth = inject(Auth);
  private googleAuth = inject(GoogleAuthProvider)

  // Observable that indicates if the user is logged in or not to other services or components
  private userIsLoggedBehaviorSubject: BehaviorSubject<boolean> ;

  // Custom variables to control the user state
  private _currentUser:any = null;


  constructor(
    public dialog:MatDialog,
    public utilsService:UtilsService,
    public languageService:LanguageService,
    ) {
      // Observable that indicates if the user is logged in or not to other services or components
      this.userIsLoggedBehaviorSubject = new BehaviorSubject<boolean>(false);
      // Link the observable with the user logged value (authenticated / unauthenticated)
      authState(this.auth).subscribe(user => {
        if (!user){
        this.setUserIsLoggedValue(false);
        }
      });
    }


    async loginWithGoogle(){
      try{
        let result = await signInWithPopup(this.auth, this.googleAuth)
        // Store the current user information
        this._currentUser = result.user       
        // Set user as logged
        this.setUserIsLoggedValue(true); 
        // Return ok to close the login popup
        return {resultOk:true}
      }catch(error) {
        return {resultOk:false, error:error}
      }
    }
  

  
    async logout(){
      try {
        // Sing out the user
        await signOut(this.auth);
      } catch (error) {}
      // Clear the class variables
      this.setUserIsLoggedValue(false)
      this._currentUser = null      
    }



  // ------ User is logged observable ----//

  // Methods to subscribe to user logged value
  setUserIsLoggedValue(newValue:boolean): void {
    this.userIsLoggedBehaviorSubject.next(newValue);
  }
  getUserIsLoggedValue(): Observable<boolean> {
    return this.userIsLoggedBehaviorSubject.asObservable();
  }




  // ------ Methods to access privat evariables ------ //

  // User  Id Token
  async getUserIdToken() {
    const user = await this.auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      return idToken;
    }
    return '';
  }

  // User Id
  getUserId(){
    return this._currentUser.uid
  }

  // User email
  getUserEmail(){
    return this._currentUser.email
  }



}
