
// Backend online server
const API_GATEWAY_URL = 'https://admin-api-6azbhvh.ew.gateway.dev/apigetaway/'

const LOCAL_TEST = 'http://127.0.0.1:8081/downloadUserList' // test


export const environment = {
    // App Name
    appName: 'admin-dev',
    // Firebase
    firebaseConfig: {
        apiKey: "AIzaSyDf5AVzPDXD48ffd4X_-HypaaClohODexs",
        authDomain: "admin-dev-2c387.firebaseapp.com",
        projectId: "admin-dev-2c387",
        storageBucket: "admin-dev-2c387.appspot.com",
        messagingSenderId: "13724678717",
        appId: "1:13724678717:web:3083c01cd61dff89468854",
        measurementId: "G-57E2GD6N14"
    },
    // Cloud run / api gateway
    googleCloudRun: {
        createNewUser: API_GATEWAY_URL+'newUser',
        getUserInformation: API_GATEWAY_URL+'getUser',
        updateUserInformation: API_GATEWAY_URL+'updateUser',
        disableUserAccount: API_GATEWAY_URL+'disableUser',
        deleteUserAccount: API_GATEWAY_URL+'deleteUser',
        downloadUserList: API_GATEWAY_URL+'downloadUserList',
        // Local test
        //downloadUserList: LOCAL_TEST,
    },
    // Production
    production: false
}