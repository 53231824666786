import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Angular modules
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog'; // Importa MatDialogModule
import { MatButtonModule } from '@angular/material/button';


// Custom services 
import { UtilsService } from '../../../services/utils/utils.service';
import { LanguageService } from '../../../services/language/language.service';


/* Specify the structure of the dictionary that will receive the dialog when being openned */
export interface DialogData {popupTitle:string, popupText:string}




@Component({
  selector: 'app-information-popup',
  standalone: true,
  imports: [
    // Angular modules
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './information-popup.component.html',
  styleUrl: './information-popup.component.css'
})
export class InformationPopupComponent {

  public popupTitle:string='';
  public popupText:string='';

  constructor(public utilsService:UtilsService,
              public languageService:LanguageService,
              public dialogRef: MatDialogRef<InformationPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData)
  {
    this.popupTitle = data.popupTitle
    this.popupText = data.popupText
  }


  // ----- Button actions ----- //
  acceptButtonClicked(){
    this.dialogRef.close()
  }

}
