import { Component } from '@angular/core';


import {CommonModule} from '@angular/common';


// Angular material components
import {MatButtonModule} from '@angular/material/button';



// Services 
import { UserAuthenticationService } from '../../../services/firestore/user-authentication.service';
import { LanguageService } from '../../../services/language/language.service';
import { GlobalVariables } from '../../../constants/global';
import { UtilsService } from '../../../services/utils/utils.service';








@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    // Angular modules
    CommonModule, 
    MatButtonModule,
    // Custom modules
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.css'
})
export class LoginPageComponent {



  constructor(
    public userAuthenticationService:UserAuthenticationService,
    public languageService:LanguageService,
    public utilsService:UtilsService){
      
    }




  async loginButtonclicked(){
    // Try to log in and wait for the response
    // Response:
    // {resultOk:bool, tfaNeeded:bool, data:firebase-user, error:firebase-error)}
    const result:any = await this.userAuthenticationService.loginWithGoogle()
    // Stop waiting popup
    if(!result.resultOk){
      if(result.error.code){
        const errorCode = result.error.code
        if(errorCode === GlobalVariables.firebaseAuth.internalError){
          return this.utilsService.displaySnackBar(this.languageService.Strings.errors.login_error_unknown_user)
        }
      }
      return this.utilsService.displaySnackBar(this.languageService.Strings.errors.login_error_unknown)
    }
  }


}
