


<div class="global-container">

    <!-- Accept button -->  
    <button 
        mat-raised-button 
        color="primary"
        class="base_buttons" 
        (click)="loginButtonclicked()">
            {{languageService.Strings.buttons.button_login}}
    </button>
    
</div>



