
<p mat-dialog-title class='popup-title text-title-size'>
    {{popupTitle}}
</p>

<mat-dialog-content>
    <p class='popup-text noraml-text-size'> {{popupText}}</p>
</mat-dialog-content>


<mat-dialog-actions id="actions-conatiner">

    <div class="base_buttons_container">
        <!-- Accept button -->        
        <button mat-raised-button 
                cdkFocusInitial
                color="primary"
                class="base_buttons"
                (click)="acceptButtonClicked()" > 
            {{languageService.Strings.buttons.button_accept}}
        </button>   
    </div>
    
</mat-dialog-actions>