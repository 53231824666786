import { Injectable } from '@angular/core';

// Angular material
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';


import { Observable } from 'rxjs';

// Generic dialogs
import { InformationPopupComponent } from '../../components/popups/information-popup/information-popup.component';
import { ConfirmationPopupComponent } from '../../components/popups/confirmation-popup/confirmation-popup.component';


// Para descargar el archivo xlsx
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';



@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private _snackBar: MatSnackBar,
    public dialog:MatDialog,
  ) {}


  // ---------- Dictionary deep copy ---------- //
  deepCopyDict(inputArray:any){
    // return structuredClone(object)   -> Right way, still beta. Will be out soon
    return JSON.parse(JSON.stringify(inputArray));
  }

  
  // ---------- Snack bar ---------- //
  displaySnackBar(message:string){
    this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition:'center',
      verticalPosition:'bottom',
    });
  }



  dictionaryToFormData(dataDict:any){
    let formData = new FormData();
    for (const key in dataDict) {
      if (Object.prototype.hasOwnProperty.call(dataDict, key)) {
        const valor = dataDict[key];
        formData.append(key, valor);
      }
    }
    return formData
  }



  // ----- Generic Dialogs ----- //

  displayInformationPopup(popupTitle:string, popupText:string){
    this.dialog.open(
      InformationPopupComponent, 
      {
        disableClose: true,
        data: {popupTitle:popupTitle, popupText:popupText},
        width: '500px',  
        //height: '500px', 
      } 
    )
  }

  displayConfirmationPopup(informationTitle:string, informationMessage:string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      disableClose: true,
      data: {informationTitle:informationTitle, informationMessage:informationMessage},
      minWidth: '300px'
    });
    return dialogRef.afterClosed();
  }


  // ----- File downloading ----- //

  downloadJsonFile(users: any, fileName: string) {
    const jsonString = JSON.stringify(users, null, 2); 
    const blob = new Blob([jsonString], { type: 'application/json' }); 
    const url = window.URL.createObjectURL(blob); 
    const a = document.createElement('a'); 
    a.href = url;
    a.download = fileName + '.json'; 
    a.click(); 
    window.URL.revokeObjectURL(url); 
  }


  downloadXlsxFile(users: any, fileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(users); 
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName + '.xlsx'); 
  }


  // ----- Get current timestamp string ----- //
  getCurrentTimestampStr(){
    const now = new Date();
    const formattedDate = now.getFullYear() + 
      ('0' + (now.getMonth() + 1)).slice(-2) + 
      ('0' + now.getDate()).slice(-2) + '_' +
      ('0' + now.getHours()).slice(-2) + 
      ('0' + now.getMinutes()).slice(-2) +
      ('0' + now.getSeconds()).slice(-2);
    return formattedDate
  }


}
