import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


// Import custom services
import { UserAuthenticationService } from '../firestore/user-authentication.service';
import { LanguageService } from '../language/language.service';
import { HttpService } from '../http/http.service';
import { UtilsService } from '../utils/utils.service';



import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ControllerService {



  constructor(
    public userAuthenticationService:UserAuthenticationService,
    public languageService:LanguageService,
    public httpService:HttpService,
    public utilsService:UtilsService,
    private router: Router
  ){}


  // Initialization method
  initializeApp(){
    // Set the default language
    this.languageService.setLanguageAccordingToNavigatorLanguage()
    // Subscribe to the user login state
    this.subscribeToUserLoginState()
  }



  // ------ Navigation actions ----//

  // Method for subscribing to the user authentication service and checking if the user is logged in or not
  // Navigate to the login or landing page accordingly  
  async subscribeToUserLoginState(){
    await this.userAuthenticationService.getUserIsLoggedValue().subscribe(async (userIsLogged:boolean) => {
      if(userIsLogged){
        // Go to landing page
        this.router.navigate(['/']);
      }else{
        //this.setCurrentAction('landingPage')
        this.router.navigate(['/login']);
      }
    });
  }

  // Methods related to the login/logout process
  logout(){
    this.userAuthenticationService.logout()
  }

  navigate(route:string){
    this.router.navigate([route])
  }




  

  // ----- Backend methods ----- //
  async addNewUser(dataDict:any){
    // Get user Id Token
    let userIdToken = await this.userAuthenticationService.getUserIdToken()
    // Get target url
    let urlString =  environment.googleCloudRun.createNewUser
    // Create the form data
    let formData = this.utilsService.dictionaryToFormData(dataDict)
    // Execute the http response and wait the result
    let resultDict = await this.httpService.executeHttpPostMethodSigned(urlString, formData, userIdToken)
    return resultDict
  }
  async getUserInformation(dataDict:any){
    // Get user Id Token
    let userIdToken = await this.userAuthenticationService.getUserIdToken()
    // Get target url
    let urlString =  environment.googleCloudRun.getUserInformation
    // Create the form data
    let formData = this.utilsService.dictionaryToFormData(dataDict)
    // Execute the http response and wait the result
    let resultDict = await this.httpService.executeHttpPostMethodSigned(urlString, formData, userIdToken)
    return resultDict
  }
  async updateUserInformation(dataDict:any){
    // Get user Id Token
    let userIdToken = await this.userAuthenticationService.getUserIdToken()
    // Get target url
    let urlString =  environment.googleCloudRun.updateUserInformation
    // Create the form data
    let formData = this.utilsService.dictionaryToFormData(dataDict)
    // Execute the http response and wait the result
    let resultDict = await this.httpService.executeHttpPostMethodSigned(urlString, formData, userIdToken)
    return resultDict
  }
  async disableUserAccount(dataDict:any){
    // Get user Id Token
    let userIdToken = await this.userAuthenticationService.getUserIdToken()
    // Get target url
    let urlString =  environment.googleCloudRun.disableUserAccount
    // Create the form data
    let formData = this.utilsService.dictionaryToFormData(dataDict)
    // Execute the http response and wait the result
    let resultDict = await this.httpService.executeHttpPostMethodSigned(urlString, formData, userIdToken)
    return resultDict
  }
  async deleteUserAccount(dataDict:any){
    // Get user Id Token
    let userIdToken = await this.userAuthenticationService.getUserIdToken()
    // Get target url
    let urlString =  environment.googleCloudRun.deleteUserAccount
    // Create the form data
    let formData = this.utilsService.dictionaryToFormData(dataDict)
    // Execute the http response and wait the result
    let resultDict = await this.httpService.executeHttpPostMethodSigned(urlString, formData, userIdToken)
    return resultDict
  }
  async downloadUserList(){
    // Get user Id Token
    let userIdToken = await this.userAuthenticationService.getUserIdToken()
    // Get target url
    let urlString =  environment.googleCloudRun.downloadUserList
    // Create the form data
    let formData = this.utilsService.dictionaryToFormData({})
    // Execute the http response and wait the result
    let resultDict = await this.httpService.executeHttpPostMethodSigned(urlString, formData, userIdToken)
    return resultDict
  }
  





}
