import { Component, OnInit } from '@angular/core';

import {CommonModule} from '@angular/common';

import {ErrorStateMatcher} from '@angular/material/core';

// Angular material components
import {FormControl, FormGroupDirective, NgForm, Validators, AbstractControl, FormBuilder, ValidatorFn, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';



// Custom components
import { WaitingSpinnerComponent } from '../../utils/waiting-spinner/waiting-spinner.component';


// services 
import { ControllerService } from '../../../services/controller/controller.service';
import { UserAuthenticationService } from '../../../services/firestore/user-authentication.service';
import { UtilsService } from '../../../services/utils/utils.service';
import { LanguageService } from '../../../services/language/language.service';

// Constants - Available configurations
import { account_config_dict } from '../../../constants/json-constants';
// Constants - Global variables
import { GlobalVariables } from '../../../constants/global';


/* Error when invalid control is dirty, touched, or submitted (input fields) */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [
    // Angular modules
    CommonModule, 
    MatButtonModule,
    MatMenuModule, 
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCheckboxModule,
    MatTabsModule,
    MatToolbarModule,
    //BrowserModule,
    // Custom modules
    WaitingSpinnerComponent,
  ],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.css'
})
export class LandingPageComponent implements OnInit {

  // Waiting popup info
  displayWaitingSpiner = false
  waitingMessage = ''

  optionMenu_AccountConfig_newUser_strValue:string = ''
  optionMenu_AccountConfig_updateUser_strValue:string = ''
  optionMenu_AccountConfig_OptionList:any = account_config_dict.values
  optionMenu_AccountConfig_newUser_predefinedOptionSelected:boolean = false
  optionMenu_AccountConfig_updateUser_predefinedOptionSelected:boolean = false

  license_types:any = account_config_dict.types
  selectedLicenseType_newUser_List:any = []
  selectedLicenseType_updateUser_List:any = []

  nDays_minValue = GlobalVariables.defaultLicenseValues.nDays_minValue
  nDays_maxValue = GlobalVariables.defaultLicenseValues.nDays_maxValue
  nDays_stepValue = GlobalVariables.defaultLicenseValues.nDays_stepValue

  nAnalysis_minValue = GlobalVariables.defaultLicenseValues.nAnalysis_minValue
  nAnalysis_maxValue = GlobalVariables.defaultLicenseValues.nAnalysis_maxValue
  nAnalysis_stepValue = GlobalVariables.defaultLicenseValues.nAnalysis_stepValue

  // Toggle button to control if the nDays and nAnalysis are being updated
  toggleState_updateUser:boolean = false 

  // Toggle button to control if the critical actions can be executed (disable / delete account)
  toggleState_criticalActions:boolean = false

  userFound:boolean = false
  userBeingEdited:boolean = false
  creationTimestamp:string = null
  updateTimestamp:string = null
  endingTimestamp:string = null
  nDays:number = null
  nAnalysis:number = null
  nRemainingAnalysis:number = null
  disabledAccount:boolean = null

  // Individual form controls
  formControl_newUser_licenseType = new FormControl( '', [
    this.selectedLicenseTypeValidator(this.selectedLicenseType_newUser_List)
  ]);
  formControl_newUser_email = new FormControl('', [
    Validators.required,
    Validators.email,
    this.spacesInStringValidators.bind(this),
  ]); 
  formControl_newUser_nDays = new FormControl('', [
    Validators.required,
    this.stepsValidator(this.nDays_stepValue),
  ]); 
  formControl_newUser_nAnalysis = new FormControl('', [
    Validators.required,
    this.stepsValidator(this.nAnalysis_stepValue),
  ]); 

  formControl_getUser_email = new FormControl('', [
    Validators.required,
    Validators.email,
    this.spacesInStringValidators.bind(this),
  ]); 
  
  formControl_updateUser_licenseType = new FormControl( '', [
    this.selectedLicenseTypeValidator(this.selectedLicenseType_updateUser_List)
  ]);
  formControl_updateUser_optionMenu = new FormControl(null, [
    Validators.required,
  ]);
  formControl_updateUser_nDays = new FormControl(0, [
    Validators.required,
    this.stepsValidator(this.nDays_stepValue),
  ]); 
  formControl_updateUser_nAnalysis = new FormControl(0, [
    Validators.required,
    this.stepsValidator(this.nAnalysis_stepValue),
  ]);

  //this.stepsValidator(tuValorDeStep),
  // Error matcher
  matcher = new MyErrorStateMatcher();


  // Form control group (need to keep this name cause its binded to parents form control)
  //    It needs to be created before constructing and initializing the view  
  formControlGroup_NewUser = this._formBuilder.group({
    email: this.formControl_newUser_email,
    nDays: this.formControl_newUser_nDays,
    nAnalysis: this.formControl_newUser_nAnalysis,
    licenseType: this.formControl_newUser_licenseType,
  }, 
  {});
  formControlGroup_GetUser = this._formBuilder.group({
    email: this.formControl_getUser_email,
  }, 
  {});
  formControlGroup_UpdateUser = this._formBuilder.group({
    optionMenu: this.formControl_updateUser_optionMenu,
    nDays: this.formControl_updateUser_nDays,
    nAnalysis: this.formControl_updateUser_nAnalysis,
    licenseType: this.formControl_updateUser_licenseType,
  }, 
  {});

  constructor(public controllerService:ControllerService,
              private _formBuilder:FormBuilder,
              public utilsService:UtilsService,
              public userAuthenticationService:UserAuthenticationService,
              public languageService:LanguageService){}


  ngOnInit(): void {
    this.initializeVariables()
  } 


  initializeVariables(){
    // Set the default values
    this.optionMenu_AccountConfig_newUser_strValue = this.languageService.Strings.landingPageStrings.optionMenu_accountConfig
    this.optionMenu_AccountConfig_updateUser_strValue = this.languageService.Strings.landingPageStrings.optionMenu_accountConfig
    this.optionMenu_AccountConfig_newUser_predefinedOptionSelected = false
    this.optionMenu_AccountConfig_updateUser_predefinedOptionSelected = false
    // Disable the input fields according to the selected value
    this.formControl_newUser_nDays.disable()
    this.formControl_newUser_nAnalysis.disable()
    // Reset the values
    this.formControlGroup_NewUser.reset()
    this.formControlGroup_GetUser.reset()
    this.formControlGroup_UpdateUser.reset()
    // Update also the checkboxes information
    this.resetLicenses()
    // Update the boolean variables for the logic
    this.userFound = false
    this.userBeingEdited = false
    // Enable form control
    this.formControl_getUser_email.enable()
  }


  resetLicenses(){
    // Empty the existing list
    this.selectedLicenseType_newUser_List.splice(0, this.selectedLicenseType_newUser_List.length);
    this.selectedLicenseType_updateUser_List.splice(0, this.selectedLicenseType_updateUser_List.length);
    // Reset the control 
    this.formControl_newUser_licenseType.updateValueAndValidity()
    this.formControl_updateUser_licenseType.updateValueAndValidity()
  }


  onTabChanged(event){
    this.initializeVariables()
  }


  getLicenses(selectedLicenseType:any){
    // Get the dictionary of the licenses that has been selected from the available ones
    let licenses = []
    for(let licenseDict of this.license_types){
      if(selectedLicenseType.includes(licenseDict.key)) licenses.push(licenseDict)
    }
    return licenses
  }


  setLicenses(licenses:any, selectedLicenseType_pointer:any){
    // Clear the existing licenses but keeping the pointer to the list memory
    selectedLicenseType_pointer.splice(0, selectedLicenseType_pointer.length)
    // Add the new keys to the list
    for(let licenseDict of licenses){
      selectedLicenseType_pointer.push(licenseDict.key)
    }
  }





  // ----- Custom validators ------ //

  // Factory function que crea el validador personalizado
  selectedLicenseTypeValidator(selectedList: any[]): ValidatorFn {
    // Retorna una función de validador
    return (control: AbstractControl): ValidationErrors | null => {
      // Aquí va la lógica de validación
      if (selectedList.length === 0) {
        // Si la lista está vacía, retorna un objeto de error
        return { 'emptyList': true };
      }
      // Si la lista no está vacía, retorna null (sin error)
      return null;
    };
  }

  specialCharactersValidators(control: AbstractControl) {
    let addmittedCharacters = /^[a-zA-Z0-9\t\n ./<>¿?;:"'`¡!@#$%^&*()\[\]{}_+=ºªñÑ,·çüÜ|\\-áéíóúÁÉÍÓÚ]*$/; // regular expression
    let currentString = control.value
    if(currentString){
      if(currentString.length > 0){
        if(!addmittedCharacters.test(currentString)){
          return { specialCharactersValidationError: true };
        }
      }
    }
    return null;
  }
  spacesInStringValidators(control: AbstractControl) {
    let currentString = control.value
    if(currentString){
      if(currentString.length > 0){
        if(currentString.indexOf(' ') >= 0){
          return { spacesInStringValidationError: true };
        }
      }
    }
    return null;
  }
  // Custom step validation functions
  stepsValidator(stepValue: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (stepValue) {
        if(this.getNumberOfDecimals(control.value) > GlobalVariables.defaultLicenseValues.maximumNumberOfDecimalPlaces) {
          return { stepValidationError: true };
        } else if (this.divisionModule(control.value, stepValue) !== 0) {
          return { stepValidationError: true };
        }
      }
      return null;
    };
  }
  getNumberOfDecimals(targetNumber:number){
    if(targetNumber){
      if(Math.floor(targetNumber) === targetNumber) return 0;
      return targetNumber.toString().split(".")[1].length; 
    }
   return 0
  }
  divisionModule(numerator:number, divider:number){
    if(divider>=1){
      return numerator%divider
    }else{
      numerator = numerator/divider
      // Round to a higher number of decimals than the step decimals to prevent aproximation errors such as 0.3/0.1=2.999999999999996
      numerator = Math.round(numerator * GlobalVariables.defaultLicenseValues.maximumNumberOfDecimalPlaces * 10000)  / (GlobalVariables.defaultLicenseValues.maximumNumberOfDecimalPlaces * 10000) 
      return numerator%1
    }
  }










  // ----- Button actions ----- //
  button_logout(){
    this.controllerService.logout()
  }

  async button_downloadUserList(){
    // Display the waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_DownloadUserList
    this.displayWaitingSpiner = true
    // Get the backend information
    let resultDict = await this.controllerService.downloadUserList()
    // Stop displaying the waiting popup
    this.displayWaitingSpiner = false
    // Manage the result
    if(Boolean(resultDict['resultOk'])){
      // Success, download the user list as a json and xlsx file
      let timestampStr = this.utilsService.getCurrentTimestampStr()
      let userList = resultDict['userList']
      this.utilsService.downloadJsonFile(userList, GlobalVariables.globalStringValues.userListFileName + timestampStr)
      this.utilsService.downloadXlsxFile(userList, GlobalVariables.globalStringValues.userListFileName + timestampStr)
    }else{
      // Failed, display the snack bar
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDict['error']['message'])
      this.utilsService.displaySnackBar(resultDict['error']['message'] + ' - ' + resultDict['error']['place'])
    }
  }


  async button_NewUser_Accept(){
    // Get the form data
    let data:any = {
      email: this.formControl_newUser_email.value,
      nDays: this.formControl_newUser_nDays.value,
      nAnalysis: this.formControl_newUser_nAnalysis.value,
      licenses: JSON.stringify(this.getLicenses(this.selectedLicenseType_newUser_List)) ,
    }
    // Display the waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_NewUser
    this.displayWaitingSpiner = true
    // Carry out the request to create the new user account
    let resultDict = await this.controllerService.addNewUser(data)
    // Stop waiting popup
    this.displayWaitingSpiner = false
    // Manage the result and display the information popup
    if(Boolean(resultDict['resultOk'])){
      // Sucess
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_newUserSuccess_title,
        this.languageService.Strings.landingPageStrings.informationPopup_newUserSuccess_message)
    }else{
      // Failed
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDict['error']['message'])
      this.utilsService.displaySnackBar(resultDict['error']['message'] + ' - ' + resultDict['error']['place'])
    }
  }

  button_NewUser_Cancel(){
   this.initializeVariables()
  }

  optionMenuClick_AccountConfig_newUser(optionIndex:number){
    // Update the information name
    this.optionMenu_AccountConfig_newUser_strValue = this.optionMenu_AccountConfig_OptionList[optionIndex].name
    // Get the predefined values
    let accountConfig = this.optionMenu_AccountConfig_OptionList[optionIndex]
    this.formControl_newUser_nDays.setValue(accountConfig.nDays)
    this.formControl_newUser_nAnalysis.setValue(accountConfig.nAnalysis)
    // Enable or disable the input fields according to the selected value
    if(optionIndex != 0){
      this.formControl_newUser_nDays.disable()
      this.formControl_newUser_nAnalysis.disable()
    }else{
      this.formControl_newUser_nDays.enable()
      this.formControl_newUser_nAnalysis.enable()
    }
    // Store that at least one option was selected
    this.optionMenu_AccountConfig_newUser_predefinedOptionSelected = true
  }

  
  async button_GetUser_Accept(){
    // Update the variable to display the information
    this.userBeingEdited = false
    this.userFound = false
    // Get the form data
    let data:any = {
      email: this.formControl_getUser_email.value,
    }
    // Display the waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_GetUser
    this.displayWaitingSpiner = true
    // Carry out the request to create the new user account
    let resultDick = await this.controllerService.getUserInformation(data)
    // Stop waiting popup
    this.displayWaitingSpiner = false
    // Manage errors
    if(resultDick['resultOk'] != true){
      // Failed
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDick['error']['message'])
      this.utilsService.displaySnackBar(resultDick['error']['message'] + ' - ' + resultDick['error']['place'])
      return 
    }
    // Update the variable to display the information
    this.userFound = true
    // Update the information
    this.creationTimestamp = resultDick['licenseDict']['creationTimestamp']
    this.updateTimestamp = resultDick['licenseDict']['updateTimestamp'] 
    this.endingTimestamp = resultDick['licenseDict']['endingTimestamp']
    this.nDays = resultDick['licenseDict']['nDays']
    this.nAnalysis = resultDick['licenseDict']['nAnalysis'] 
    this.nRemainingAnalysis = resultDick['licenseDict']['nRemainingAnalysis']
    this.disabledAccount = resultDick['disabled']
    this.setLicenses(resultDick['licenseDict']['licenses'], this.selectedLicenseType_updateUser_List)
    // Disable form control for email, so the user cannot be modified
    this.formControl_getUser_email.disable()
    // Disable form control for licenses nDays and nAnalysis
    this.toggleState_updateUser = false
    this.formControl_updateUser_optionMenu.disable()
    this.formControl_updateUser_nDays.disable()
    this.formControl_updateUser_nAnalysis.disable()
    this.toggleState_criticalActions = false
    // Update the information name
    this.optionMenu_AccountConfig_updateUser_strValue = this.languageService.Strings.landingPageStrings.optionMenu_accountConfig
    this.optionMenu_AccountConfig_updateUser_predefinedOptionSelected = false
  }

  async button_GetUser_Cancel(){
   this.initializeVariables()
  }
  


  optionMenuClick_AccountConfig_updateUser(optionIndex:number){
    // Update the information name
    this.optionMenu_AccountConfig_updateUser_strValue = this.optionMenu_AccountConfig_OptionList[optionIndex].name
    // Get the predefined values
    let accountConfig = this.optionMenu_AccountConfig_OptionList[optionIndex]
    this.formControl_updateUser_nDays.setValue(accountConfig.nDays)
    this.formControl_updateUser_nAnalysis.setValue(accountConfig.nAnalysis)
    this.formControl_updateUser_optionMenu.setValue(this.optionMenu_AccountConfig_updateUser_strValue)
    // Unable or disable the updatable values (nDays, nAnalysis) depending on the selected value
    if(optionIndex == 0){
      this.formControl_updateUser_nDays.enable()
      this.formControl_updateUser_nAnalysis.enable()
      this.optionMenu_AccountConfig_updateUser_predefinedOptionSelected = false
    }else{
      this.formControl_updateUser_nDays.disable()
      this.formControl_updateUser_nAnalysis.disable()
      this.optionMenu_AccountConfig_updateUser_predefinedOptionSelected = true
    }
  }


  async button_GetUser_Edit(){
    // Update the variable to display the information
    this.userBeingEdited = true
  }

  async button_GetUser_Update(){
    // Get the form data
    let data:any = {
      email: this.formControl_getUser_email.value,
      licenses: JSON.stringify(this.getLicenses(this.selectedLicenseType_updateUser_List)) ,
      updateLicenseParameters: this.toggleState_updateUser, // nDays and nAnalysis
      nDays: this.formControl_updateUser_nDays.value,
      nAnalysis: this.formControl_updateUser_nAnalysis.value,
    }   
    // Display the waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_UpdateUser
    this.displayWaitingSpiner = true
    // Carry out the request to create the new user account
    let resultDick = await this.controllerService.updateUserInformation(data)
    // Stop waiting popup
    this.displayWaitingSpiner = false
    // Manage errors
    if(resultDick['resultOk'] != true){
      // Failed
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDick['error']['message'])
      this.utilsService.displaySnackBar(resultDick['error']['message'] + ' - ' + resultDick['error']['place'])
      return 
    }
    // Display success popup
    this.utilsService.displayInformationPopup(
      this.languageService.Strings.landingPageStrings.informationPopup_userUpdated_title,
      this.languageService.Strings.landingPageStrings.informationPopup_userUpdated_message)
    // Disable the fields that can be updated
    this.toggleState_updateUser = false
    this.formControl_updateUser_optionMenu.disable()
    this.optionMenu_AccountConfig_updateUser_strValue = this.languageService.Strings.landingPageStrings.optionMenu_accountConfig
    this.optionMenu_AccountConfig_updateUser_predefinedOptionSelected = false
    this.formControl_updateUser_nDays.setValue(null)
    this.formControl_updateUser_nAnalysis.setValue(null)
    this.formControl_updateUser_nDays.disable()
    this.formControl_updateUser_nAnalysis.disable()
    this.toggleState_criticalActions = false
    // Update the variable to display the information
    this.userBeingEdited = false
    // Update the information
    this.creationTimestamp = resultDick['licenseDict']['creationTimestamp']
    this.updateTimestamp = resultDick['licenseDict']['updateTimestamp'] 
    this.endingTimestamp = resultDick['licenseDict']['endingTimestamp']
    this.nDays = resultDick['licenseDict']['nDays']
    this.nAnalysis = resultDick['licenseDict']['nAnalysis'] 
    this.nRemainingAnalysis = resultDick['licenseDict']['nRemainingAnalysis']
    this.disabledAccount = resultDick['disabled']
    this.setLicenses(resultDick['licenseDict']['licenses'], this.selectedLicenseType_updateUser_List)
  }






  
  // ----- Check boxes ---- //

  getCategoryIsChecked_newUser(key){
    if(this.selectedLicenseType_newUser_List.includes(key)) return true
    return false
  }

  getCategoryIsChecked_updateUser(key){
    if(this.selectedLicenseType_updateUser_List.includes(key)) return true
    return false
  }

  handleChangeCheckBox_newUser(event, index){
    let key = this.license_types[index]['key']
    if (this.selectedLicenseType_newUser_List.includes(key)) {
      const index = this.selectedLicenseType_newUser_List.indexOf(key);
      this.selectedLicenseType_newUser_List.splice(index, 1);
    } else {
      this.selectedLicenseType_newUser_List.push(key)
    }
    this.formControl_newUser_licenseType.updateValueAndValidity()
  }
  
  handleChangeCheckBox_updateUser(event, index){
    let key = this.license_types[index]['key']
    if (this.selectedLicenseType_updateUser_List.includes(key)) {
      const index = this.selectedLicenseType_updateUser_List.indexOf(key);
      this.selectedLicenseType_updateUser_List.splice(index, 1);
    } else {
      this.selectedLicenseType_updateUser_List.push(key)
    }
    this.formControl_updateUser_licenseType.updateValueAndValidity()
  }

  



  // ----- Toggle butttons ---- //
  onToggleChange_updateUser(event:any){
    if(event.checked){
      this.toggleState_updateUser = true
      this.formControl_updateUser_optionMenu.enable()
    } 
    else{
      this.toggleState_updateUser = false
      this.formControl_updateUser_optionMenu.disable()
      this.formControl_updateUser_nDays.disable()
      this.formControl_updateUser_nAnalysis.disable()
      // Reset the predefined values
      this.optionMenu_AccountConfig_updateUser_strValue = this.languageService.Strings.landingPageStrings.optionMenu_accountConfig
      this.optionMenu_AccountConfig_updateUser_predefinedOptionSelected = false
      this.formControl_updateUser_optionMenu.setValue(null)
      this.formControl_updateUser_nDays.setValue(null)
      this.formControl_updateUser_nAnalysis.setValue(null)
    }   
  }


  onToggleChange_criticalActions(event:any){
    if(event.checked){
      this.toggleState_criticalActions = true
    }else{
      this.toggleState_criticalActions = false
    }
  }
  
  









  // ------- Critical actions ------- //

  // Disable account
  button_CriticalAction_DisableAccount(){
    // Display a confirmation popup before executing the critical action
    let titleString = this.languageService.Strings.landingPageStrings.criticalAccion_title
    let messagetring = this.languageService.Strings.landingPageStrings.criticalAccion_disableAccount
    this.utilsService.displayConfirmationPopup(titleString, messagetring).subscribe(result => {
      // If results is True (Accept): Execute the action - Disable account
      if (result) this.CriticalAction_DisableAccount()
    });
  }

  // Delete account
  button_CriticalAction_DeleteAccount(){
    // Display a confirmation popup before executing the critical action
    let titleString = this.languageService.Strings.landingPageStrings.criticalAccion_title
    let messagetring = this.languageService.Strings.landingPageStrings.criticalAccion_deleteAccount
    this.utilsService.displayConfirmationPopup(titleString, messagetring).subscribe(result => {
      // If results is True (Accept): Execute the action - Delete account
      if (result) this.CriticalAction_DeleteAccount()
    });
  }




  async CriticalAction_DisableAccount(){
    // Get the data dict
    let data:any = {email: this.formControl_getUser_email.value}
    // Display the waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_UpdateUser
    this.displayWaitingSpiner = true
    // Carry out the request to create the new user account
    let resultDick = await this.controllerService.disableUserAccount(data)
    // Stop waiting popup
    this.displayWaitingSpiner = false
    // Manage errors
    if(resultDick['resultOk'] != true){
      // Failed
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDick['error']['message'])
      this.utilsService.displaySnackBar(resultDick['error']['message'] + ' - ' + resultDick['error']['place'])
      return 
    }
    // Display success popup
    this.utilsService.displayInformationPopup(
      this.languageService.Strings.landingPageStrings.informationPopup_userUpdated_title,
      this.languageService.Strings.landingPageStrings.informationPopup_userUpdated_message)
    // Update the variable to display the information
    this.userBeingEdited = false
    this.toggleState_criticalActions = false
  }




  async CriticalAction_DeleteAccount(){
    // Get the data dict
    let data:any = {email: this.formControl_getUser_email.value}
    // Display the waiting popup
    this.waitingMessage = this.languageService.Strings.landingPageStrings.waitingMessage_DeleteUser
    this.displayWaitingSpiner = true
    // Carry out the request to create the new user account
    let resultDick = await this.controllerService.deleteUserAccount(data)
    // Stop waiting popup
    this.displayWaitingSpiner = false
    // Manage errors
    if(resultDick['resultOk'] != true){
      // Failed
      this.utilsService.displayInformationPopup(
        this.languageService.Strings.landingPageStrings.informationPopup_error_title,
        resultDick['error']['message'])
      this.utilsService.displaySnackBar(resultDick['error']['message'] + ' - ' + resultDick['error']['place'])
      return 
    }
    // Display success popup
    this.utilsService.displayInformationPopup(
      this.languageService.Strings.landingPageStrings.informationPopup_userDeleted_title,
      this.languageService.Strings.landingPageStrings.informationPopup_userDeleted_message)
    // Update the variable to display the information
    this.userBeingEdited = false
    this.toggleState_criticalActions = false
    this.userFound = false
  }


}

