import { Routes } from '@angular/router';
import { AuthGuardService } from './services/guards/auth-guard.service';

import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';





export const routes: Routes = [

    { path: '', component: LandingPageComponent, canActivate: [AuthGuardService] }, 
    { path: 'login', component: LoginPageComponent },  
  ];