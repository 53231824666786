

export const GlobalVariables = {
    // Firebase auth
    firebaseAuth: {
        internalError: 'auth/internal-error',
    },
    // Default license values
    defaultLicenseValues: {
        maximumNumberOfDecimalPlaces: 2,
        nDays_minValue: 0,
        nDays_maxValue: 1000,
        nDays_stepValue: 1,
        nAnalysis_minValue: 0,
        nAnalysis_maxValue: 1000,
        nAnalysis_stepValue: 1,
        userTrust_minValue: 0,
        userTrust_maxValue: 10,
        userTrust_stepValue: 1
    },
    // Global string values
    globalStringValues: {
        userListFileName:'Users_FN_'
    }
}