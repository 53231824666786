import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private _http: HttpClient) { }

  async executeHttpPostMethodSigned(urlString:string, bodyDictionary:any, userIdToken:string){   
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + userIdToken
      }),
    };
    try{
      let result = await this._http.post<any>(urlString, bodyDictionary, httpOptions).toPromise()
      return result
    }catch (error){
      console.log('ERROR')
      console.error(error);
      let customErrorCode = error['error']['error']
      return {'resultOk': false, 'error':customErrorCode}
    }
  }

}
