import { Component, Input } from '@angular/core';

import {MatProgressBarModule} from '@angular/material/progress-bar';


@Component({
  selector: 'app-waiting-spinner',
  standalone: true,
  imports: [
    MatProgressBarModule
  ],
  templateUrl: './waiting-spinner.component.html',
  styleUrl: './waiting-spinner.component.css'
})
export class WaitingSpinnerComponent {

  // Input parameters. Pass from the caller class binding the input through the HTML
  @Input() waitingMessage:string = '';

}
