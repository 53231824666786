export const StringsES = {
    // Registration and authentication processes
    authentication: {
        register_success: 'Verifique su correo electrónico utilizando el enlace que te hemos enviado',
        error_emailNotVerified: 'El correo no ha sido verificado',
        resetPasswordemail_sent: 'Se ha enviado un correo a ',
        resetPasswordemail_emailNotFound: 'No se encuentra el usuario',
        resetPasswordemail_unknownError: 'Error desconocido, intentelo más tarde o contacte con soporte',
        resetPasswordemail_required: 'Es necesario restaurar la contraseña',
    },
    // Popups
    popups: {
        input_box_label_user: 'Usuario',
        input_box_label_password: 'Contraseña',
        resetPasswordPopup_title: 'Restablecer contraseña',
        title_verifyemail: 'Email no verificado',
        text_verifyEmail: '¿Enviar correo de verificación a ',
        title_unavailable: 'Mantenimiento en curso',
        text_unavailable: 'Funcionalidad temporalmente no disponible',
        title_idToken: 'Error',
        text_idToken: 'No ha sido posible obtener autorización',
    },
    // Buttons
    buttons: {
        button_back: 'Volver',
        button_login: 'Entrar',
        button_accept: 'Aceptar',
        button_cancel: 'Cancelar',
        button_logout: 'Salir',
        button_settings: 'Configuración',
        button_save: 'Guardar',
        button_exit: 'Salir',
        button_edit: 'Editar',
        button_update: 'Actualizar',
        button_disableAccount: 'Deshabilitar cuenta',
        button_deleteAccount: 'Eliminar cuenta',
        buttons_downloadUserList: 'Descargar lista de usuarios',
    },
    // Errors
    errors: {
        // Login errros 
        login_error_unknown_user: 'Usuario sin acceso a la plataforma',
        login_error_unknown: 'Error desconocido',
        // Format (generic validators)
        error_maxValue: 'Valor demasiado grande',
        error_minValue: 'Valor demasiado pequeño',
        error_step: 'Resolución demasiado elevada',
        error_required: 'Campo obligatorio',
        error_maxLength: 'Demasiados caracteres',
        error_specialCharacters: 'Caracteres no permitidos',
        error_spacesInString: 'No puede contener espacios',
    },
    // Global form / Specific form fields
    booleanFormField: {
        tag_positive: 'Sí',
        tag_negative: 'No',
    },
    // Landing page strings
    landingPageStrings: {
        optionMenu_accountConfig: 'Tipo de licencia',
        optionMenu_accountConfig_valueCustom: 'Personalizada',
        tab_newUser: 'Nuevo usuario',
        tab_existingUser: 'Usuario existente',
        input_email: 'Email',
        input_nDays: 'Duración (dias)',
        input_nAnalysis: 'Numero de análisis',
        label_startingDate: 'Fecha de creación',
        label_updateDate: 'Fecha de actualización',
        label_endingDate: 'Fecha de finalización',
        label_nDays: 'Duración (dias)',
        label_nAnalysis: 'Numero de análisis',
        label_nRemainingAnalysis: 'Numero de análisis restantes',
        label_disabledAccount: 'Cuenta desactivada',
        label_userTrust: 'Confianza para entrenar los modelos',
        button_disableAccount: 'Deshabilitar cuenta',
        button_deleteAccount: 'Eliminar cuenta',
        toggle_updateLicenseDaysAndAnalysis: 'Modificar parámetros',
        toggle_dangerousAcctions: 'Acciones críticas',
        waitingMessage_NewUser: 'Creando usuario',
        waitingMessage_GetUser: 'Buscando usuario',
        waitingMessage_UpdateUser: 'Actualizando usuario',
        waitingMessage_DeleteUser: 'Eliminando cuenta de usuario',
        waitingMessage_DownloadUserList: 'Descargando lista de usuarios',
        informationPopup_userUpdated_title: 'Exito',
        informationPopup_userUpdated_message: 'Usuario actualizado correctamente',
        informationPopup_newUserSuccess_title: 'Exito',
        informationPopup_newUserSuccess_message: 'Usuario creado correctamente',
        informationPopup_userDeleted_title: 'Exito',
        informationPopup_userDeleted_message: 'Cuenta de usuario eliminada',
        informationPopup_error_title: 'Error',
        criticalAccion_title: 'Alerta',
        criticalAccion_disableAccount: '¿Deshabilitar cuenta?',
        criticalAccion_deleteAccount: '¿Eliminar cuenta?',
    },
    loginPageStrings: {
        waitingMessage_Login: 'Verificando credensiales',
    },

}