import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, take, Observable } from 'rxjs';

import { UserAuthenticationService } from '../firestore/user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  private router = inject(Router);

  // Dependes of user authentication service
  private userAuthenticationService = inject(UserAuthenticationService)
  canActivate(): Observable<boolean> {
    return this.userAuthenticationService.getUserIsLoggedValue().pipe(
      take(1), // Tomar el valor actual y completar
      map(isAuthenticated => {
        if (isAuthenticated) {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }

  
}
